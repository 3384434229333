import React from 'react'
import Link from 'gatsby'
import Dev from '../images/dev-icon.svg'
import A11y from '../images/a11y-icon.svg'
import Support from '../images/support-icon.svg'

const Services = () => (
  <div>
    <ul className="cards">
      <li>
        <div className="icon-box">
          <img src={Dev} alt />
          <h2>Design + Development</h2>
        </div>
        <p>
          We specialize in building dynamic websites using content management
          systems, like Drupal and WordPress, along with other technologies.
        </p>
      </li>
      <li>
        <div className="icon-box">
          <img src={A11y} alt />
          <h2>Accessibility Consulting</h2>
        </div>
        <p>
          We audit and optimize the design and functionality of new and existing
          websites to ensure that all users are included and have a great
          experience.
        </p>
      </li>
      <li>
        <div className="icon-box">
          <img src={Support} alt />
          <h2>Support + Maintenance</h2>
        </div>
        <p>
          We offer support to assist with security, performance, and other code
          updates, as well as help with content strategy and implementing
          feature requests.
        </p>
      </li>
    </ul>
  </div>
)

export default Services
